import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  *, ::after, ::before {
    box-sizing: border-box;
  }

  html, body {
    display: flex;
    margin: 0;
    flex-direction: column;
    flex: 1;
    min-height: 100%;
  }

  html,
  body,
  div,
  form,
  fieldset,
  legend,
  label {
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'Inter', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  th,
  td,
  caption,
  p {
    font-weight: normal;
    margin: 0;
    padding: 0;
  }
`

export default GlobalStyles

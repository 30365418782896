import { Logo, Text, ThemeProvider } from '@lemonenergy/lemonpie'
import { Links, LiveReload, Scripts, ScrollRestoration } from '@remix-run/react'
import styled, { css } from 'styled-components'

import Section from '../Section'

import { useRouteErrorWithReporter } from '~/hooks/useRouteErrorWithReporter'
import GlobalStyles from '~/styles/GlobalStyles'
import Html from '~/styles/Html'

const CatchSection = styled(Section)(
  ({ theme: { spacing } }) => css`
    gap: ${spacing.sm};
    flex: 1;
  `,
)

export const GenericErrorSection = () => {
  return (
    <CatchSection variant="primary.main">
      <Logo variant="symbol" size="lg" />

      <Text forwardedAs="p" variant="subtitle100" textAlign="center">
        Opa, infelizmente não encontramos o que você estava procurando...
      </Text>
    </CatchSection>
  )
}

export default function GenericErrorBoundary() {
  const error = useRouteErrorWithReporter()

  if (process.env.NODE_ENV !== 'production') console.error(error)

  return (
    <ThemeProvider>
      <GlobalStyles />
      <Html>
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <Links />
          {typeof window === 'undefined' && '__STYLES__'}
        </head>

        <body>
          <GenericErrorSection />
        </body>

        <ScrollRestoration />
        <Scripts />

        {process.env.NODE_ENV === 'development' && <LiveReload />}
      </Html>
    </ThemeProvider>
  )
}

import { isRouteErrorResponse, useRouteError } from '@remix-run/react'
import { captureRemixErrorBoundaryError, withScope } from '@sentry/remix'

/**
 * Collect error and report to sentry
 * @returns The original remix useRouteError value
 */
export const useRouteErrorWithReporter = () => {
  const error = useRouteError()

  if (isRouteErrorResponse(error) && error.status !== 404) {
    // captureRemixErrorBoundaryError expects a object to do a spread
    // https://github.com/getsentry/sentry-javascript/blob/c9aaf8b7db326e2e39254301137f6b3310abc294/packages/remix/src/client/errors.tsx#L21
    if (typeof error.data === 'string') {
      error.data = { data: error.data }
    }

    withScope(scope => {
      scope.setContext('Error Response in Remix Boundary', {
        status: error.status,
        statusText: error.statusText,
        data: error.data,
      })
      captureRemixErrorBoundaryError(error)
    })
  }

  return error
}
